import '../scss/auth/styles.scss';
import ClipboardJS from 'clipboard';
import {Tooltip} from "bootstrap";

const clipboard = new ClipboardJS('.btn-clipboard', {
    target: trigger => trigger.closest('.bd-code-snippet').querySelector('.highlight'),
    text: trigger => trigger.closest('.bd-code-snippet').querySelector('.highlight').textContent.trimEnd()
})

clipboard.on('success', event => {
    const iconFirstChild = event.trigger.querySelector('.bi').firstElementChild
    const namespace = 'http://www.w3.org/1999/xlink'
    const originalXhref = iconFirstChild.getAttributeNS(namespace, 'href')

    const isCheckIconVisible = originalXhref.endsWith('#check2')
    if (isCheckIconVisible) {
        return
    }

    const tooltip = Tooltip.getInstance(event.trigger);
    tooltip.setContent({ '.tooltip-inner': event.trigger.dataset.bsCopiedTitle });

    event.trigger.addEventListener('hidden.bs.tooltip', () => {
        tooltip.setContent({ '.tooltip-inner': event.trigger.dataset.bsOriginalTitle })
    }, { once: true })
    iconFirstChild.setAttributeNS(namespace, 'href', originalXhref.replace('clipboard', 'check2'))

    event.clearSelection();

    setTimeout(() => {
        iconFirstChild.setAttributeNS(namespace, 'href', originalXhref)
        event.trigger.title = event.trigger.dataset.bsOriginalTitle
    }, 2000)
});

clipboard.on('error', event => {
    console.error('Action:', event.action);
    console.error('Trigger:', event.trigger);
});
